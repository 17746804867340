'use client';

const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'localhost';

/**
 * Set a cookie
 * @param cName - The name of the cookie
 * @param cValue - The value of the cookie
 * @param maxAge - The max age of the cookie in seconds
 */
export const setCookie = (cName: string, cValue: string, maxAge = 3600 * 24): void => {
  const today = new Date();
  today.setTime(today.getTime() + maxAge * 1000);

  const whenWillExpire = `expires=${today.toUTCString()}`;
  const secure = COOKIE_DOMAIN !== 'localhost';
  const sameSite = 'Lax';

  document.cookie = `${cName}=${cValue}; ${whenWillExpire}; Secure=${secure}; SameSite=${sameSite}; Domain=${COOKIE_DOMAIN}; Path=/; Priority=High`;
};

/**
 * Get a cookie value by name
 * @param cName - The name of the cookie
 * @returns The cookie value or an empty string if not found
 */
export const getCookie = (cName: string): string => {
  const name = `${cName}=`;
  const decodedCookie = typeof document !== 'undefined' ? decodeURIComponent(document.cookie) : '';
  const cookies = decodedCookie.split(';');

  for (const cookie of cookies) {
    const currentCookie = cookie.trim();
    if (currentCookie.startsWith(name)) {
      return currentCookie.substring(name.length);
    }
  }

  return '';
};

/**
 * Remove specified cookies
 * @param cookies - An array of cookie names to remove
 */
export const removeCookies = (cookies: string[]): void => {
  cookies.forEach(name => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=${COOKIE_DOMAIN}`;
  });
};

/**
 * Read a cookie from a server-provided cookie string
 * @param decodedCookie - The cookie string from the server
 * @param cName - The name of the cookie to retrieve
 * @returns The cookie value or an empty string if not found
 */
export const readServerCookie = (decodedCookie: string, cName: string): string => {
  const name = `${cName}=`;
  const cookies = decodedCookie.split(';');

  for (const cookie of cookies) {
    const currentCookie = cookie.trim();
    if (currentCookie.startsWith(name)) {
      return currentCookie.substring(name.length);
    }
  }

  return '';
};
